import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {TermwebConfiguration} from '@twpub/core/models';
import {TermwebStatusService} from '@twpub/core/services';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

@Component({
  selector: 'pub-termweb-connection-settings-form',
  templateUrl: './termweb-connection-settings-form.component.html',
  styleUrls: ['./termweb-connection-settings-form.component.scss']
})
export class TermwebConnectionSettingsFormComponent implements OnChanges {
  @Input() configuration: TermwebConfiguration | undefined;

  @Output() saveConfiguration: EventEmitter<TermwebConfiguration> = new EventEmitter<TermwebConfiguration>();

  configForm: FormGroup = this.fb.group({
    id: new FormControl<number>(0),
    url: new FormControl<string>('', [Validators.pattern('^(https?)://[^\\s/$.?#].[^\\s]*$'), Validators.required]),
    client: new FormControl<string>('', [Validators.required]),
    userName: new FormControl<string>('', [Validators.required]),
    password: new FormControl<string>('', [Validators.required])
  });

  passwordLabel: string = 'Password';


  constructor(private logger: NGXLogger, private fb: FormBuilder, private statusService: TermwebStatusService, private snackbar: MatSnackBar) {
  }

  ngOnChanges(): void {
    if (this.configuration) {
      const editConfiguration = {...this.configuration, password: ''} as TermwebConfiguration;
      this.passwordLabel = 'Set new password';
      this.configForm.controls['password'].setValidators(null);
      this.configForm.controls['password'].updateValueAndValidity()
      this.configForm.setValue(editConfiguration);
    }
  }

  save() {
    if (this.configForm.valid) {
      this.statusService.attemptConnection(this.configForm.value).subscribe((status) => {
        if (status.ok) {
          this.saveConfiguration.emit(this.configForm.value);
          this.configForm.reset();
          this.showMessage(`Connected to ${status.message}`, 'success');
        } else {
          this.showMessage(status.message, 'error');
        }
      });
    }
  }

  cancel() {
    this.configForm.reset();
    this.saveConfiguration.emit();
  }

  private showMessage(msg: string, type: string = 'info'): void {
    const config: MatSnackBarConfig = {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: ['snackbar-' + type]
    };
    this.snackbar.open(msg, undefined, config)
  }
}
